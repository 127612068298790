import React from "react";

const MapIndonesia: React.FC = () => {
  return (
    <div className="w-full bg-blue-300 mt-24 py-8">
      <div className="container mx-auto lg:flex justify-between py-4">
        <div className="flex-row w-full lg:w-3/5 items-center justify-center text-white">
          <h1 className="text-4xl text-center font-bold">PENGIRIMAN SELURUH INDONESIA</h1>
          <br></br>
          <p className="text-justify lg:text-start">
            Dengan semangat ingin berkontribusi dalam turut serta mendukung pembangunan dalam negeri, kami slalu berinovasi agar mampu menyajikan produk dan layanan berkualitas demi kepuasan pelanggan. Saat ini kami telah mampu menjangkau hingga ke berbagai daerah di Indonesia. Berbagai proyek perumahan, gedung bertingkat, mall dan sebagainya sudah menggunakan produk bata ringan dari KAP Bataringan AAC.
          </p>
        </div>
        <div className="w-full mt-8 lg:mt-0 lg:w-2/5 flex justify-center lg:justify-end" data-aos="zoom-out-up" data-aos-delay="100">
            <img src="https://static.vecteezy.com/system/resources/thumbnails/014/341/785/small/doodle-freehand-drawing-of-indonesia-map-free-png.png" alt="map_pengiriman" />
        </div>
      </div>
    </div>
  );
};

export default MapIndonesia;
