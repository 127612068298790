import {
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Card,
} from "@material-tailwind/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export function ListEcommerce() {
  const openNewTabShopee = () => {
    const url = "https://shopee.co.id/bataringanaac_palembang"
    window.open(url)
  }

  const openNewTabToped = () => {
    const url = "https://www.tokopedia.com/bataringanplg"
    window.open(url)
  }

  return (
    <Card className="w-full lg:w-[22rem] overflow-hidden rounded-md mt-4 lg:mt-0" data-aos="flip-right" data-aos-delay="500">
      <List className="my-2 p-0">
        <ListItem className="group rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white" onClick={openNewTabToped}>
          <ListItemPrefix>
            <img
              src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/36c1015e.png"
              className="w-[2em] h-auto"
              alt=""
            />
          </ListItemPrefix>
          <p  className="text-lg">Bata Ringan AAC Palembang</p>
        </ListItem>
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white" onClick={openNewTabShopee}>
          <ListItemPrefix>
            <img
              src="https://static.vecteezy.com/system/resources/previews/027/076/117/original/shopee-logo-transparent-free-png.png"
              className="w-[2em] h-auto"
              alt=""
            />
          </ListItemPrefix>
          <p className="text-lg">Bata Ringan AAC Palembang</p>
        </ListItem>
      </List>
    </Card>
  );
}
