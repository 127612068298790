import { Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const currentYear = new Date().getFullYear();

const openNewTab = () => {
  const message = encodeURIComponent("Halo, mau tanya-tanya dulu terkait batu bata ringannya ya")
  const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text="+message
  window.open(WhatsappURL)
}
const openNewTabInstagram = () => {
  const url = "https://www.instagram.com/kapbataringan/"
  window.open(url)
}

const openNewTabShopee = () => {
  const url = "https://shopee.co.id/bataringanaac_palembang"
  window.open(url)
}

const openNewTabToped = () => {
  const url = "https://www.tokopedia.com/bataringanplg"
  window.open(url)
}

export function Footer() {
  return (
    <footer
      className="mt-16 relative w-full text-white pt-4"
      style={{ backgroundColor: "rgb(39 39 42)" }}
    >
      <div className="mx-auto w-full container">
        <div className="grid grid-cols-1 items-center justify-center gap-4 md:grid-cols-2">
          <div className="justify-center justify-self-center mx-auto">
            <Typography variant="h5" className="mb-6">
              PT. KARYA ADYAKTA PERKASA
            </Typography>
            <div className="w-[16rem]">
              <img
                src="./images/logo.jpeg"
                alt="logo"
                className="rounded-full w-full"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 justify-between gap-4">
            <div>
              <div>
                <Typography
                  variant="small"
                  color="white"
                  className="mb-3 font-bold opacity-100 text-xl"
                >
                  Contact Person
                </Typography>
                <div className="flex items-center w-full mb-3 cursor-pointer" onClick={openNewTab}>
                  <div className="w-1/5 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faWhatsapp}
                      style={{ fontSize: "1.5em", color: "#1fee11" }}
                    />
                  </div>
                  <div className="w-4/5">
                    <p className="text-lg">082183056369</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-3 cursor-pointer" onClick={openNewTabInstagram}>
                  <div className="w-1/5 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ fontSize: "1.5em", color: "#d62976 " }}
                    />
                  </div>
                  <div className="w-4/5">
                    <p className="text-lg">@kapbataringan</p>
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <Typography
                  variant="small"
                  color="white"
                  className="mb-3 font-bold opacity-100 text-xl"
                >
                  Online Store
                </Typography>
                <div className="flex items-center w-full mb-3 cursor-pointer" onClick={openNewTabToped}>
                  <div className="w-1/5 flex items-center justify-center">
                    <img
                      src="https://assets.tokopedia.net/assets-tokopedia-lite/v2/arael/kratos/36c1015e.png"
                      className="w-[1.5em] h-auto"
                      alt=""
                    />
                  </div>
                  <div className="w-4/5">
                    <p className="text-md">Bata Ringan AAC Palembang</p>
                  </div>
                </div>
                <div className="flex items-center w-full mb-3 cursor-pointer" onClick={openNewTabShopee}>
                  <div className="w-1/5 flex items-center justify-center">
                    <img
                      src="https://static.vecteezy.com/system/resources/previews/027/076/117/original/shopee-logo-transparent-free-png.png"
                      className="w-[1.5em] h-auto"
                      alt=""
                    />
                  </div>
                  <div className="w-4/5">
                    <p className="text-md">Bata Ringan AAC Palembang</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Typography
                variant="small"
                color="white"
                className="mb-3 font-bold opacity-100 text-xl"
              >
                Location
              </Typography>
              <div className="flex items-center w-full mb-3">
                <p className="text-md">
                  Jl. Bypass Alang-Alang Lebar, Alang Alang Lebar, Kec.
                  Alang-Alang Lebar, Kota Palembang, Sumatera Selatan 30961
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 flex w-full flex-col items-center justify-center border-t border-blue-white-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-blue-white-900 md:mb-0"
          >
            &copy; {currentYear} <a href="#">PT. KARYA ADYAKTA PERKASA</a>. All
            Rights Reserved.
          </Typography>
        </div>
      </div>
    </footer>
  );
}
