// FloatingIcon.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faInstagram } from "@fortawesome/free-brands-svg-icons";

const FloatingIcon: React.FC = () => {
  const openNewTab = () => {
    const message = encodeURIComponent("Halo, mau tanya-tanya dulu terkait batu bata ringannya ya")
    const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text="+message
    window.open(WhatsappURL)
  }
 
  return (
    <div className="fixed bottom-4 left-4" onClick={openNewTab}>
      {/* Your icon component or content */}
      <div className="text-white rounded-full p-2 cursor-pointer shadow-lg">
        <img
          src=" https://cdn-icons-png.flaticon.com/512/3670/3670051.png"
          className="w-[3em] h-auto"
          alt=""
        />
      </div>
    </div>
  );
};

export default FloatingIcon;
