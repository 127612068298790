import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Card, List, ListItem, Avatar } from "@material-tailwind/react";
import '../../Styles.css';

const Header: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [scrolling, setScrolling] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleScroll = () => {
      setScrollPos(window.scrollY);
      setScrolling(true);

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setScrolling(false);
      }, 450); // Adjust the timeout duration based on your preference
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <header
      className={`text-black fixed top-0 w-full z-10`}
    >
      {/* Desktop Site */}
      <div className={`hidden md:flex lg:flex xl:flex 2xl:flex w-full p-2 bg-white shadow-md ${
        scrolling ? 'slide-up' : 'fixed slide-down'
      }`}>
        <div className="container mx-auto flex">
          <img
            src="./images/logo-white.jpeg"
            alt="logo"
            className="w-16 mr-4"
          />
          <h1 className="text-2xl cursor-pointer font-bold mr-6 flex items-center text-blue-400">
            KAPBATARINGAN
          </h1>
          <div className="flex items-center">
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#Home"
            >
              Beranda
            </a>
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#AboutUs"
            >
              Tentang Kami
            </a>
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#Product"
            >
              Produk
            </a>
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#Calculator"
            >
              Kalkulator
            </a>
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#ContactUs"
            >
              Kontak
            </a>
            <a
              className="new-line-animation font-normal cursor-pointer mx-4"
              href="#Review"
            >
              Ulasan
            </a>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div
        className={` ${
          isSidebarOpen ? "h-auto" : "h-0"
        } md:hidden lg:hidden xl:hidden 2xl:hidden`}
      >
        <div className={`flex p-2 bg-white w-full ${
          scrolling ? 'slide-up' : 'slide-down'
        }`}>
          <div className="flex items-center ml-2" onClick={handleToggleSidebar}>
            <FontAwesomeIcon icon={faBars} style={{ fontSize: "1.5em" }} />
          </div>
          <div className="flex-grow flex items-center justify-center">
            <img
              src="./images/logo-white.jpeg"
              alt="logo"
              className="w-16 mr-4"
            />
            <h1 className="text-xl font-bold items-center text-blue-400">
              KAPBATARINGAN
            </h1>
          </div>
        </div>
        <div
          className={`transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform ease-in-out duration-300`}
        >
          <Card className="-mt-1 max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5 rounded-none">
            <List>
              <a className="font-normal cursor-pointer" href="#Home">
                <ListItem onClick={handleToggleSidebar}>Beranda</ListItem>
              </a>
              <a className="font-normal cursor-pointer" href="#AboutUs">
                <ListItem onClick={handleToggleSidebar}>Tentang Kami</ListItem>
              </a>
              <a className="font-normal cursor-pointer" href="#Product">
                <ListItem onClick={handleToggleSidebar}>Produk</ListItem>
              </a>
              <a className="font-normal cursor-pointer" href="#Calculator">
                <ListItem onClick={handleToggleSidebar}>Kalkulator</ListItem>
              </a>
              <a className="font-normal cursor-pointer" href="#ContactUs">
                <ListItem onClick={handleToggleSidebar}>Kontak</ListItem>
              </a>
              <a className="font-normal cursor-pointer" href="#Review">
                <ListItem onClick={handleToggleSidebar}>Ulasan</ListItem>
              </a>
            </List>
          </Card>
        </div>
      </div>
    </header>
  );
};

export default Header;
