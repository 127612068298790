import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const FAQ: React.FC = () => {
  const [open, setOpen] = React.useState(0);
  const [alwaysOpen, setAlwaysOpen] = React.useState(true);

  const handleAlwaysOpen = () => setAlwaysOpen((cur) => !cur);
  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);

  return (
    <div className="mt-24 container mx-auto">
      <div className="flex items-center justify-center">
        <h1 className="text-4xl font-bold">Ada Pertanyaan ?</h1>
      </div>
      <div>
        <Accordion open={open === 0}>
          <AccordionHeader onClick={() => handleOpen(0)}>
            Apa perbedaan bata ringan dan bata merah?
          </AccordionHeader>
          <AccordionBody>
           
          Bata ringan atau bisa juga disebut hebel terbuat dari adonan semen, batu kapur, pasir silika, kuarsa, aluminium bubuk, gipsum, dan air. Selain tingkat kerapatannya yang baik karena dimensinya presisi, bata ringan juga kedap suara serta tahan api dan jamur. Hal ini disebabkan adonan bata ringan diawetkan dengan cara dipanaskan dan diberi tekanan tinggi menggunakan mesin autoclave berteknologi Jerman. 
          <br /><br />Sedangkan batu bata merah merah terbuat dari tanah liat melalui proses pembakaran dengan suhu tinggi hingga keras dan mengering dalam bentuk balok persegi panjang. Yang kemudian dikeringkan secara alami menggunakan sinar matahari langsung sedangkan bata ringan langsung menggunakan oven (mesin autoclave). Dalam penggunaannya, bata merah memerlukan bahan perekat yang cukup banyak yaitu air dan semen. Bagi pekerja juga memerlukan waktu yang lebih lama dikarenakan ukuran dari bata merah yang kecil dan tidak presisi, sehingga pekerja harus lebih lama menyusunnya. 
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 1}>
          <AccordionHeader onClick={() => handleOpen(1)}>
            Apabila saya memerlukan bata ringan dalam jumlah besar, apakah
            tersedia jasa kirimnya?
          </AccordionHeader>
          <AccordionBody>
            Kami berkomitmen untuk memberikan pelayanan terbaik kepada mitra bisnis kami, termasuk layanan pengiriman produk kami agar sampai tepat waktu ke lokasi Anda. 
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 2}>
          <AccordionHeader onClick={() => handleOpen(2)}>
            Bagaimana cara untuk saya menghitung kebutuhan bata ringan yang
            diperlukan?
          </AccordionHeader>
          <AccordionBody>
            Anda dapat menggunakan fitur calculator hitung kami di website ini,
            atau langsung menghubungi team kami.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 3}>
          <AccordionHeader onClick={() => handleOpen(3)}>
            Apakah saya bisa berdiskusi untuk kebutuhan bata ringan jangka
            panjang (pengiriman berkala dalam kurun waktu tertentu)?
          </AccordionHeader>
          <AccordionBody>
            Bisa langsung menghubungi WA kami yang tertera dalam website ini atau bisa dengan mengklik icon WA yang tersedia di website ini.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 4}>
          <AccordionHeader onClick={() => handleOpen(4)}>
            Apabila saya ingin menjadi agen distributor, apakah ada syarat dan
            ketentuan?
          </AccordionHeader>
          <AccordionBody>
            Kami membuka peluang bagi siapapun yang ingin bergabung menjadi agen distributor dari PT. Karya Adyakta Perkasa dengan syarat dan ketentuan yang sudah kami tetapkan. Apabila Anda berminat, segera hubungi kontak resmi kami yang tertera di website ini.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 5}>
          <AccordionHeader onClick={() => handleOpen(5)}>
            Apakah bata ringan lebih rapuh dari bata merah?
          </AccordionHeader>
          <AccordionBody>
            Bata hebel menggunakan pengikat seperti semen dalam proses produksinya agar lebih kuat, sehingga kekuatannya jauh lebih baik daripada bata merah yang hanya menggunakan tanah liat.
          </AccordionBody>
        </Accordion>
        <Accordion open={open === 6}>
          <AccordionHeader onClick={() => handleOpen(6)}>
            Apa perbedaan AAC dan CLC?
          </AccordionHeader>
          <AccordionBody>
            Perbedaan bata ringan AAC dengan CLC dari segi proses pengeringan yaitu AAC mengalami pengeringan dalam oven autoklaf bertekanan tinggi sedangkan bata ringan jenis CLC yang mengalami proses pengeringan alami.
          </AccordionBody>
        </Accordion>

      </div>
    </div>
  );
};

export default FAQ;
