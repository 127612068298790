import { Card, Typography } from "@material-tailwind/react";

const TABLE_ROWS = [
  {
    spec: "Tebal",
    unit: "mm",
    val1: "75",
    val2: "100",
    val3: "125",
    val4: "150",
    val5: "175",
    val6: "200",
  },
  {
    spec: `Luas Dinding / m³`,
    unit: "m³",
    val1: "13.33",
    val2: "10",
    val3: "8",
    val4: "6.67",
    val5: "5.71",
    val6: "5",
  },
  {
    spec: "Isi / m³",
    unit: "Blok",
    val1: "111.11",
    val2: "83.33",
    val3: "66.67",
    val4: "55.56",
    val5: "47.62",
    val6: "41.67",
  },
];

export function TableSpecification() {
  return (
    <Card className="overflow-x-auto">
      <table className="table-auto text-left">
        <tbody>
          {TABLE_ROWS.map(
            ({ spec, unit, val1, val2, val3, val4, val5, val6 }, index) => (
              <tr key={spec} className="even:bg-blue-gray-50/50">
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {spec}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {unit}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val1}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val2}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val3}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val4}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val5}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {val6}
                  </Typography>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </Card>
  );
}
