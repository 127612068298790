// SplashScreen.tsx
import React, { useEffect, useState } from "react";
import { Spinner } from "@material-tailwind/react";

const SplashScreen: React.FC = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Simulate a delay (e.g., 2000 milliseconds) for the splash screen
    const splashTimeout = setTimeout(() => {
      setShowSplash(false);
    }, 1000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(splashTimeout);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50 transition-opacity duration-500 ${
        showSplash ? "" : "opacity-0 pointer-events-none"
      }`}
    >
      <div className="flex-col items-center justify-center">
        <div className="text-4xl font-bold text-blue-400 mb-8">KAPBATARINGAN</div>
        <div className="flex items-center justify-center">
          <Spinner className="h-16 w-16 text-gray-900/50" />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
