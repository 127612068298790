import {
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Card,
} from "@material-tailwind/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    faWhatsapp,
    faInstagram 
} from '@fortawesome/free-brands-svg-icons';

import { faLocationDot } from '@fortawesome/free-solid-svg-icons';

export function ListSocmed() {
  const openNewTab = () => {
    const message = encodeURIComponent("Halo, mau tanya-tanya dulu terkait batu bata ringannya ya")
    const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text="+message
    window.open(WhatsappURL)
  }
  const openNewTabInstagram = () => {
    const url = "https://www.instagram.com/kapbataringan/"
    window.open(url)
  }

  return (
    <Card className="w-full lg:w-[22rem] overflow-hidden rounded-md" data-aos="flip-left" data-aos-delay="100">
      <List className="my-2 p-0">
        <ListItem className="group rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white" onClick={openNewTab}>
          <ListItemPrefix>
            <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: '2em', color: '#1fee11'}} />
          </ListItemPrefix>
          <p  className="text-lg">082183056369</p>
        </ListItem>
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white" onClick={openNewTabInstagram}>
          <ListItemPrefix>
            <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '2em', color: '#d62976 '}} />
          </ListItemPrefix>
          <p className="text-lg">@kapbataringan</p>
        </ListItem>
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700 hover:bg-blue-500 hover:text-white focus:bg-blue-500 focus:text-white">
          <ListItemPrefix>
            <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '2em', color: 'rgb(30, 48, 80) '}} />
          </ListItemPrefix>
          <p className="text-md">Jl. Bypass Alang-Alang Lebar, Alang Alang Lebar, Kec. Alang-Alang Lebar, Kota Palembang, Sumatera Selatan 30961</p>
        </ListItem>
      </List>
    </Card>
  );
}
