import React from "react";
import { Carousel as MyCarousel } from "@material-tailwind/react";

const Carousel: React.FC = () => {
  return (
    <div className="h-[36rem] w-full mt-[4.7rem] bg-gray-100">
        <MyCarousel 
            autoplay={true}
            loop={true}
            className=""
            navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                  {new Array(length).fill("").map((_, i) => (
                    <span
                      key={i}
                      className={`block h-1 cursor-pointer rounded-2xl transition-all shadow content-[''] ${
                        activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                      }`}
                      onClick={() => setActiveIndex(i)}
                    />
                  ))}
                </div>
              )}
        >
        <img
            src="./images/banner1.png"
            alt="hebel 1"
            className="h-full w-full object-contain"
        />
        <img
            src="./images/banner2.png"
            alt="hebel 2"
            className="h-full w-full object-contain"
        />
        <img
            src="./images/banner3.png"
            alt="hebel 3"
            className="h-full w-full object-contain"
        />
        </MyCarousel>
    </div>
  );
};

export default Carousel;
