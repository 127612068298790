import {
  Input,
  InputProps,
  Option,
  Select,
  Typography,
  InputSizeStylesType,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import Calculator from "../components/Calculator";

interface RepeatedCalculatorProps {
  index: number;
  updatedTotalPcs: (newTotalPcs: number) => void;
  updatedTotalCubic:  (newTotalCubic: number) => void;
}

interface Calculator {
  length: number
  width: number
  height: number
  hebelCoefficient: number
  total: number
  totalHebel: number
}

const init: Calculator[] = Array.from({ length: 100 }, () => ({
  length: 0,
  width: 0,
  height: 0,
  hebelCoefficient: 0,
  total: 0,
  totalHebel: 0,
}));

const finalTotalPcs: number[] = Array(100).fill(0);
const finalTotalCubic: number[] = Array(100).fill(0);

const RepeatedCalculator: React.FC<RepeatedCalculatorProps> = ({ index,  updatedTotalPcs, updatedTotalCubic, }) => {
  
  const [calculator, setCalculator] = useState<Calculator[]>(init);
 
  const updateLength = (index: number, value: string) => {
    calculator[index].length = parseInt(value, 10) || 0;
    updateTotal();
  };

  const updateHeight = (index: number, value: string) => {
    calculator[index].height = parseInt(value, 10) || 0;
    updateTotal();
  };

  const updateHebelCoefficient = (index: number, value: string) => {
    const val = value !== undefined ? value.toString() : "";
    calculator[index].hebelCoefficient = parseInt(val, 10) || 0;
    updateTotal();
  };

  const updateTotalPcs = () => {
    var totalPcs = 0
    var totalCubic = 0

    finalTotalPcs.forEach((element, index) => {
      totalPcs += element
    })
    finalTotalCubic.forEach((element, index) => {
      totalCubic += element
    })
    updatedTotalPcs(totalPcs)
    updatedTotalCubic(totalCubic)
  }

  const updateTotal = () => {
    const calc = calculator[index];
    if (calc.hebelCoefficient !== 0 && calc.height !== 0 && calc.length !== 0) {
      const tot = (calc.height * calc.length) / calc.hebelCoefficient;
      const totHebel = (calc.height * calc.length) / 0.12;
      calculator[index].total = tot;
      calculator[index].totalHebel = totHebel;
      finalTotalCubic[index] = tot
      finalTotalPcs[index] = totHebel
      updateTotalPcs()
    } else {
      calculator[index].total = 0;
      calculator[index].totalHebel = 0;
    }
  };

  return (
    <div>
      <h3 className="font-bold text-xl mt-2">Kebutuhan Bata Ringan {index}</h3>
      <div className="gap-6 mt-4 w-full lg:flex">
        <div className="flex-col w-full">
          <Typography variant="small">
            Panjang Bangunan (P)<i className="text-red-600">*</i>
          </Typography>
          <div className="flex">
            <Input
              id="length"
              size="md"
              style={{ borderRadius: "0px" }}
              className=""
              type="number"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              containerProps={{ className: "min-w-[90px]" }}
              crossOrigin={Input}
              onChange={(e) => updateLength(index, e.target.value)}
            />
            <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
              m
            </span>
          </div>
        </div>
        <div className="flex-col w-full">
          <Typography variant="small">
            Tinggi Bangunan (T)<i className="text-red-600">*</i>
          </Typography>
          <div className="flex">
            <Input
              id="height"
              type="number"
              size="lg"
              style={{ borderRadius: "0px" }}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin={Input}
              containerProps={{ className: "min-w-[90px]" }}
              onChange={(e) => updateHeight(index, e.target.value)}
            />
            <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
              m
            </span>
          </div>
        </div>
        <div className="flex-col w-full">
          <Typography variant="small">
            Bata Ringan (cm)<i className="text-red-600">*</i>
          </Typography>
          <div className="flex">
            <Select
              id="hebelCoefficient"
              style={{ borderRadius: "0px" }}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              containerProps={{ className: "min-w-[100px] h-11" }}
              onChange={(e) =>
                updateHebelCoefficient(
                  index,
                  e !== undefined ? e.toString() : ""
                )
              }
            >
              <Option value="13.33">60 x 20 x 7.5</Option>
              <Option value="10">60 x 20 x 10</Option>
            </Select>
            <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
              cm
            </span>
          </div>
        </div>
        <div className="flex-col w-full">
          <Typography variant="small">Jumlah Bata Ringan</Typography>
          <div className="flex">
            <Input
              disabled
              size="lg"
              style={{ borderRadius: "0px" }}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin={Input}
              containerProps={{ className: "min-w-[90px]" }}
              value={Math.floor(calculator[index].totalHebel)}
            />
            <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
              m
            </span>
          </div>
        </div>
        <div className="flex-col w-full">
          <Typography variant="small">Volume Bata Ringan</Typography>
          <div className="flex">
            <Input
              disabled
              size="lg"
              style={{ borderRadius: "0px" }}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin={Input}
              containerProps={{ className: "min-w-[90px]" }}
              value={calculator[index].total.toFixed(3)}
            />
            <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
              m<sup>3</sup>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepeatedCalculator;
