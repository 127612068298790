import React from "react";
import { ListSocmed } from "../design/ListSocmed";
import { SimpleRegistrationForm } from "../design/Form";
import { ListEcommerce } from "../design/ListEcommerce";

const ContactUs: React.FC = () => {
  return (
    <div className="container mx-auto mt-32">
      <div className="lg:flex mt-8">
        <div className="w-full lg:w-2/3 mr-8">
          <p className="font-bold text-5xl pb-8 w-full">
            Jangan ragu untuk menghubungi kami, tim kami siap melayani Anda.
          </p>
          <p className="pb-8 text-xl w-full">
            Terima kasih atas minat Anda untuk melihat dan membeli produk batu
            bata ringan ini. Kami sangat mempertimbangkan komunikasi dengan
            pelanggan.
          </p>  
          <div className="items-center justify-center lg:flex lg:justify-between lg:items-start">
            <ListSocmed/>
            <ListEcommerce data-aos="flip-right"/>
          </div>
        </div>
        <div className="w-full lg:w-1/3" data-aos="fade-right" data-aos-delay="1000">
            <SimpleRegistrationForm/>
        </div>
      </div>
      <div className="mt-16" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.6329454847664!2d104.67965997570076!3d-2.9214482396011667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e3b758406706105%3A0x153609cc79b7843c!2sBATA%20RINGAN%20AAC%20PALEMBANG!5e0!3m2!1sen!2sid!4v1704212544618!5m2!1sen!2sid" className="w-full h-96" loading="lazy"></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
