import React, { useState } from "react";
import RepeatedCalculator from "../design/RepeatedCalculator";
import { Button, Input, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const Calculator: React.FC = () => {
  const [calculatorCount, setCalculatorCount] = useState(1);
  const addCalculator = () => {
    setCalculatorCount((prevCount) => prevCount + 1);
  };

  const [totalPcs, setTotalPcs] = useState(0);
  const [totalCubic, setTotalCubic] = useState(0);

  const updateTotalPcsInParent = (newTotalPcs: number) => {
    setTotalPcs(newTotalPcs);
  };

  const updateTotalCubicInParent = (newTotalCubic: number) => {
    setTotalCubic(newTotalCubic);
  };
  return (
    <div className="container mx-auto mt-24">
      <div className="flex justify-center my-4">
        <h1 className="text-4xl font-bold">Kalkulator</h1>
      </div>
      <hr />
      {Array.from({ length: calculatorCount }, (_, index) => (
        <RepeatedCalculator key={index} index={index + 1} updatedTotalPcs= {updateTotalPcsInParent} updatedTotalCubic={updateTotalCubicInParent} />
      ))}
      <hr className="mt-4" />
      <Button
        onClick={addCalculator}
        className="mb-4 bg-blue-500 text-white p-4"
        style={{ borderRadius: "0px" }}
      >
        <FontAwesomeIcon icon={faCirclePlus} className="mr-2" />
        Tambah Kalkulator
      </Button>
      <div className="lg:flex items-center justify-between mt-4">
        <Typography variant="h3" color="blue-gray" className="mb-2">
          Total Kebutuhan Bata Ringan
        </Typography>
        <div className="flex">
          <Input
            value={Math.floor(totalPcs)}
            disabled
            size="lg"
            style={{ borderRadius: "0px" }}
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            crossOrigin={Input}
            containerProps={{ className: "min-w-[150px]" }}
          />
          <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700 md:mr-2">
            pcs
          </span>
        </div>
        <div className="flex mt-4 lg:mt-0">
          <Input
            value={totalCubic.toFixed(3)}
            disabled
            size="lg"
            style={{ borderRadius: "0px" }}
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            crossOrigin={Input}
            containerProps={{ className: "min-w-[150px]" }}
          />
          <span className="flex items-center justify-center w-12 bg-gray-200 text-gray-700">
            m<sup>3</sup>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
