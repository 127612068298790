import React from "react";
import { Carousel,IconButton  } from "@material-tailwind/react";

const Benefit: React.FC = () => {
  return (
    <div
      className="mt-24 relative w-full text-white py-16 bg-blue-300"
    >
      <div className="container mx-auto ">
        <div className="flex justify-center mb-8">
          <h1 className="text-4xl text-center font-bold">
            Keunggulan Kapbataringan Palembang
          </h1>
        </div>
        <div className="flex justify-center">
          <Carousel className="rounded-xl md:max-w-[36rem] shadow"
            prevArrow={({ handlePrev }) => (
                <IconButton
                  variant="gradient"
                  color="blue"
                  size="md"
                  onClick={handlePrev}
                  className="!absolute top-2/4 left-4 -translate-y-2/4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                    />
                  </svg>
                </IconButton>
              )}
              nextArrow={({ handleNext }) => (
                <IconButton
                  variant="gradient"
                  color="blue"
                  size="md"
                  onClick={handleNext}
                  className="!absolute top-2/4 !right-4 -translate-y-2/4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                </IconButton>
              )}
          >
            <img
              src="./images/benefit1.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit2.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit3.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit4.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit5.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit6.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
            <img
              src="./images/benefit7.png"
              alt="benefit"
              className="h-full w-full object-cover"
            />
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
