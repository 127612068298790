import React from "react";
import { Reviewer } from "../design/Reviewer";

const Review: React.FC = () => {
    return(
        <div className="container mx-auto mt-24">
            <div>
                <p className="text-4xl font-bold">Apa kata mereka tentang Kapbataringan ?</p>
            </div>
            <div className="flex my-8 scroll-smooth overflow-x-auto w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
                <Reviewer profile="https://lh3.googleusercontent.com/a-/ALV-UjV-PUw5tEiTEu-892CBs-6u1K6ke8llQvXTbA089Km35mQ=w36-h36-p-rp-mo-br100" name="Sere Eunice" description="Harganya sesuai kualitas, barangnya bagus, plus admin sangat membantu saat di tanya2 padahal di awal aku gak ngerti apa2. Penjelasannya rinci dan mudah dipahami orang awam"/>
                <Reviewer profile="https://lh3.googleusercontent.com/a-/ALV-UjUsHZYknJpx24TcZUKjX23x0fSe_UPxrDiW1Xu4m6sG9w=w36-h36-p-rp-mo-br100" name="DnD Hadi" description="Pengerjaan cepat... kualitas bersaing, paling penting buat saya, respon yg selalu ngasih solusi sangat membantu sekali buat saya yang ga begitu ngerti... thank you, recommended deh pkoknya.." />
                <Reviewer profile="https://lh3.googleusercontent.com/a-/ALV-UjXQrcSNGjsTid5O99jenQagetVo-C9rLDG7cMuz3LdQ51s=w36-h36-p-rp-mo-br100" name="Juliaa Lauraa" description="Pengerjaan jdi lebih cepat pake batu ringan dari toko ini aku order disinii super best seller harga jgaa lebih murah nextt mau order lagi, pelayanan nya juga goodd"/>
                <Reviewer profile="https://lh3.googleusercontent.com/a/ACg8ocIY9bbDwMta2pftjK1oeXTX3LU0S8fS80CqROFlMUCK=w36-h36-p-rp-mo-br100" name="Christ Michael" description="Batanya benar2 ringan, tidak berat sama skali. Diangkat 1 jari bisa, terpercaya deh, pelayanan ramah jg, bakal jdi pelanggan tetap"/>
                <Reviewer profile="https://lh3.googleusercontent.com/a/ACg8ocIJv-nUC8EOrhqcaSQGJMxDlgeqI737f9j-Qc-Sqnu6=w36-h36-p-rp-mo-br100" name="Marini Rini" description="Bata nya bagus dan ringan ramah lingkungan dan pengerjaannya lebih cepat nggak memakan waktu lama saat di pasang, bahannya ramah lingkungan 👍🙏 …"/>
            </div>
        </div>
    )
}

export default Review