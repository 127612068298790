import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import emailjs from 'emailjs-com';

export function SimpleRegistrationForm() {
  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    emailjs.sendForm('service_ppo42ce', 'template_5pnenjj', e.target as HTMLFormElement, 'NiBW-UKsPPzIPsaGR')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <Card color="transparent" shadow={false} >
      <form className="mt-8 mb-2" onSubmit={sendEmail}>
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3 text-xl">
            Nama
          </Typography>
          <Input
            name="from_name"
            placeholder="Budiawan"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            crossOrigin={undefined}
          />
          <Typography variant="h6" color="blue-gray" className="-mb-3 text-xl">
            Email
          </Typography>
          <Input
            name="from_email"
            placeholder="budiawan@mail.com"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            crossOrigin={undefined}
          />
          <Typography variant="h6" color="blue-gray" className="-mb-3 text-xl">
            Isi Pesan
          </Typography>
          <Textarea
            name="message"
            placeholder=" Mau pesan bata ringan 100 box"
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
          />
        </div>
        <Button className="mt-6 bg-blue-400 text-md" fullWidth type="submit">
          Kirim Email
        </Button>
      </form>
    </Card>
  );
}
