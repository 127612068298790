import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrowelBricks,
  faMagnifyingGlass,
  faRecycle,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";

const InfoSection: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  const countingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const targetCount = 100000;
    const duration = 2000; // Animation duration in milliseconds
    const steps = 500; // Number of steps

    const increment = targetCount / steps;
    const interval = duration / steps;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const timer = setInterval(() => {
            setCount((prevCount) => {
              const newCount = prevCount + increment;
              return newCount >= targetCount ? targetCount : newCount;
            });
          }, interval);

          return () => clearInterval(timer); // Cleanup interval on component unmount
        }
      },
      { threshold: 0.5 } // Adjust the threshold based on your needs
    );

    if (countingRef.current) {
      observer.observe(countingRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on component unmount
  }, []);

  return (
    <div
      className="mt-32 relative w-full text-white lg:h-60 flex justify-center items-center"
      style={{ backgroundColor: "rgb(39 39 42)" }}
    >
      <div className="container grid grid-cols-1 lg:grid-cols-4 gap-8 py-4 justify-between w-full">
        <div className="flex w-full justify-center">
          <div className="flex w-64">
            <div>
              <FontAwesomeIcon
                icon={faTrowelBricks}
                style={{ fontSize: "4em", color: "rgb(96 165 250)" }}
              />
            </div>
            <div className="px-2">
              <div ref={countingRef} data-aos="zoom-in-up">
                <p className="text-3xl font-bold">{Math.round(count)}+</p>
              </div>
              <div>
                <p className="text-xl font-bold">Bata Terjual</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div className="flex w-64">
            <div>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ fontSize: "4em", color: "rgb(96 165 250)" }}
              />
            </div>
            <div className="px-2" >
              <div>
                <p className="text-3xl font-bold"  data-aos="zoom-in-up" data-aos-delay="300">3x</p>
              </div>
              <div>
                <p className="text-xl font-bold">Quality Check</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div className="flex w-64">
            <div>
              <FontAwesomeIcon
                icon={faMoneyBill}
                style={{ fontSize: "4em", color: "rgb(96 165 250)" }}
              />
            </div>
            <div className="px-2">
              <div  data-aos="zoom-in-up" data-aos-delay="600">
                <p className="text-3xl font-bold">2x</p>
              </div>
              <div>
                <p className="text-xl font-bold">Lebih Murah</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <div className="flex w-64">
            <div>
              <FontAwesomeIcon
                icon={faRecycle}
                style={{ fontSize: "4em", color: "rgb(96 165 250)" }}
              />
            </div>
            <div className="px-2"  data-aos="zoom-in-up" data-aos-delay="900">
              <div>
                <p className="text-3xl font-bold">Bahan</p>
              </div>
              <div>
                <p className="text-xl font-bold">Ramah Lingkungan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
