import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { TableSpecification } from "../design/TableSpecification";

const Product: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <div className="mt-32 container mx-auto">
      <div className="flex items-center justify-center">
        <h1 className="text-4xl font-bold">Produk</h1>
      </div>
      <div className="mt-16 flex-col items-center">
        <div className="my-8">
          <Card className="w-full flex-col lg:flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-full lg:w-2/5 shrink-0 lg:rounded-r-none"
            >
              <img
                src="./images/product1.jpeg"
                alt="product hebel"
                className="h-full w-full object-fit"
              />
            </CardHeader>
            <CardBody className="w-full">
              <Typography
                variant="h6"
                color="gray"
                className="mb-4 uppercase"
                data-aos="fade-in"
                data-aos-delay="200"
              >
                Batu Bata Ringan
              </Typography>
              Bata Ringan/Hebel disebut juga Autoclaved Aerated Concrete ( AAC )
              diproduksi dengan standar yang tinggi untuk menjamin kualitas.
              <Typography
                variant="h4"
                color="blue-gray"
                className="my-2"
                data-aos="fade-in"
                data-aos-delay="500"
              >
                Tersedia Ukuran
              </Typography>
              <Typography
                color="gray"
                className="font-normal"
                data-aos="fade-in"
                data-aos-delay="500"
              >
                <ul>
                  <li>60 x 7,5 x 20</li>
                  <li>60 x 10 x 20</li>
                </ul>
              </Typography>
              <Typography
                variant="h4"
                color="blue-gray"
                className="my-2"
                data-aos="fade-in"
                data-aos-delay="500"
              >
                Spesifikasi
              </Typography>
              <TableSpecification />
              <a className="inline-block mt-4">
                <Button
                  onClick={handleOpen}
                  variant="text"
                  className="flex items-center gap-2"
                  data-aos="fade-in"
                  data-aos-delay="400"
                >
                  Selengkapnya
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                    />
                  </svg>
                </Button>
              </a>
            </CardBody>
          </Card>
        </div>
      </div>

      <Dialog open={open} handler={handleOpen} className="h-[38rem]">
        <DialogHeader>BATU BATA RINGAN</DialogHeader>
        <DialogBody className="max-h-[29rem] mt-2 scroll-smooth overflow-y-auto w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
          <div className="w-full flex justify-center">
            <img src="./images/dialog2.jpg" alt="" className="w-112" />
          </div>
          <p className="text-justify mt-4">
            Bata Ringan/Hebel disebut juga Autoclaved Aerated Concrete ( AAC )
            diproduksi dengan standar yang tinggi untuk menjamin kualitas. Bata
            Ringan/Hebel AAC terbuat dari pasir slica dan semen berkualitas
            tinggi serta ramah lingkungan dan di proses dengan teknologi aerasi
            terbaik membuat Bata Ringan/Hebel AAC lebih kuat dari Bata
            Konvensional bahkan sama kuat nya dengan beton, namun lebih ringan
            sehingga mempermudah aplikasi dan ketepatan estimasi pembangunan
            serta menawarkan solusi cerdas untuk bangunan anda.
            <br /> <br />
          </p>
          Keunggulan Bata Ringan/Hebel AAC <br />
          - Cepat dan Rapi
          <br />
          - Daya Serap Air Rendah
          <br />
          - Hemat dan Akurat
          <br />
          - Tahan Api
          <br />
          - Sejuk dan Hemat Energi - Tahan Bising
          <br />- Tahan Gempa
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>Kembali</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Product;
