import React from "react";
import { BackgroundCard } from "../design/BackgroundCard";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";

const Portfolio: React.FC = () => {
  return (
    <div className="container mx-auto mt-24">
      <div className="flex justify-center">
        <h1 className="text-4xl font-bold mb-8">Portfolio</h1>
      </div>
      <div className="grid items-center justify-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/amarta%20hq.png?alt=media&token=0b3e8d8e-3870-4a84-8e42-11b1fcdb35a9')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Amart HQ
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/PCPD%20tower.png?alt=media&token=64e99f80-957b-465f-9ea6-e9201ddbbcb3')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              PCPD Tower
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/anandamaya%20residences.png?alt=media&token=fdca6233-7f59-4961-9244-34ea7d754af4')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Andamaya Residence
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/ciputra%20international.png?alt=media&token=6416ed22-0bed-42fb-82f3-030f417b07f2')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Ciputra International
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/indonesia%20one.png?alt=media&token=4b871587-9c18-4724-b102-bd1c2dfa9ca6')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Indonesia One
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/mayora%20group.png?alt=media&token=3a38f6f6-b0fd-463b-a372-c4408cb7ef64')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Mayora Group
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/puri%20mansion.png?alt=media&token=7483570b-7b4a-4379-800c-d1ee64249444')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Puri Mansion
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/setiabudi%20sky%20garden%20kuningan.png?alt=media&token=b90fe0d8-4e87-43b0-bd9f-61233549b0d9')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              Setiabudi Sky Garden Kuningan
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>

        <Card
          shadow={false}
          className="relative grid h-[35rem] w-[22rem] md:w-full items-end justify-center overflow-hidden text-center transform transition-transform duration-300 hover:scale-110"
        >
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className={`absolute inset-0 m-0 h-full w-full rounded-none bg-[url('https://firebasestorage.googleapis.com/v0/b/kapbataringan.appspot.com/o/the%20pakubuwono%20spring.png?alt=media&token=868cbede-39e7-40b4-b8d3-a24d662a3399')] bg-cover bg-center`}
          >
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
          </CardHeader>
          <CardBody className="relative py-14 px-6 md:px-12">
            <Typography
              variant="h2"
              color="white"
              className="mb-6 font-medium leading-[1.5]"
            >
              The Pakubuwono Spring
            </Typography>
            <Typography variant="h5" className="mb-4 text-gray-400">
              Jakarta, Indonesia
            </Typography>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Portfolio;
