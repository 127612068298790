import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

const AboutUs: React.FC = () => {
  return (
    <div className="container mx-auto mt-24">
        <div className="flex items-center justify-center">
            <h1 className="text-4xl font-bold text-center">PT KARYA ADYAKTA PERKASA</h1>   
        </div>
        <div className="mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <Card className="mb-12 lg:mb-0" data-aos="fade-up" data-aos-delay="100">
                    <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        src="./images/aboutus1.png"
                        alt="tentang-kapbataringanpalembang"
                        className="w-full h-full"
                        />
                    </CardHeader>
                    <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                        Tentang Kami
                        </Typography>
                        <Typography>
                        PT KARYA ADYAKTA PERKASA berlokasi di Palembang, Sumatera Selatan. Kami merupakan perusahaan yang bergerak khusus dalam bidang distributor bahan bangunan modern seperti bata ringan, panel lantai, dan semen mortar. Perusahaan kami juga melayani pembelian di seluruh daerah di Indonesia.
                        </Typography>
                    </CardBody>
                </Card>
                <Card className="mb-12 lg:mb-0" data-aos="fade-up" data-aos-delay="300">
                    <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        src="./images/aboutus2.jpeg"
                        alt="tentang-kapbataringanpalembang"
                        />
                    </CardHeader>
                    <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                        Mengapa Memilih Kami ?
                        </Typography>
                        <Typography>
                        PT KARYA ADYAKTA PERKASA didukung oleh sumber daya yang berkualitas dan berbekal keahlian dan tentu pengalaman di bidangnya dengan tujuan memberikan pelayanan terbaik kepada mitra bisnis kami dengan kualitas pekerjaan, pelayanan, dan harga yang terbaik.
                        </Typography>
                    </CardBody>
                </Card>
                <Card className="" data-aos="fade-up" data-aos-delay="600">
                    <CardHeader color="blue-gray" className="relative h-56">
                        <img
                        src="./images/aboutus3.jpeg"
                        alt="tentang-kapbataringanpalembang"
                        className="w-full h-full"
                        />
                    </CardHeader>
                    <CardBody>
                        <Typography variant="h5" color="blue-gray" className="mb-2">
                        Ruang Lingkup
                        </Typography>
                        <Typography>
                        Perusahaan kami melayani pembelian di seluruh daerah di Indonesia.
                        </Typography>
                    </CardBody>
                </Card>
            </div>
        </div>
    </div>
  );
};

export default AboutUs;
